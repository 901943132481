<template>
  <table-container :list="list">
    <el-table-column prop="id" label="ID" width="50" align="center">
    </el-table-column>
    <el-table-column prop="name" label="类型名称" width="180" align="center">
    </el-table-column>
    <el-table-column prop="valid" label="当前代理数" width="120" align="center">
    </el-table-column>
    <el-table-column prop="desc" label="备注" align="center"> </el-table-column>
    <el-table-column prop="created_at" label="创建日期" align="center">
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template slot-scope="scope">
        <el-button type="primary" @click="handleEdit(scope.row)">编辑</el-button
        ><delete-btn :item="scope.row" @del="handleDelete"></delete-btn>
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    handleEdit(item) {
      this.$emit("edit", item);
    },
    async handleDelete(item) {
      const res = await this.$store.dispatch("delAgentType", {
        agent_cate_id: item.id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>