<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="80px"
    >
      <el-form-item label="类型名称:">
        <el-input v-model.trim="name"></el-input>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input v-model.trim="desc"></el-input>
      </el-form-item>
      <el-form-item label="排序:">
        <el-input v-model.trim="sort"></el-input>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
export default {
  name: "EditType",
  props: {
    type: String,
    show: Boolean,
    item: Object,
  },
  data() {
    return {
      hide: false,
      title: "新增代理类型",
      labelPosition: "right",
      id: "",
      name: "",
      sort: 1,
      desc: "",
    };
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增代理类型";
        this.name = "";
        this.sort = 1;
        this.desc = "";
      } else {
        this.title = "编辑代理类型";
        this.id = this.item.id;
        this.name = this.item.name;
        this.sort = this.item.sort;
        this.desc = this.item.desc;
      }
    },
  },
  methods: {
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      let fromData = {
        name: this.name,
        desc: this.desc,
        sort: this.sort,
      };
      if (this.type === "add") {
        this.handleAddComfirm(fromData);
        return;
      } else {
        fromData.id = this.id;
        this.handleEditComfirm(fromData);
      }
    },
    //新增提交
    async handleAddComfirm(fromData) {
      const res = await this.$store.dispatch("addAgentType", fromData);

      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(fromData) {
      const res = await this.$store.dispatch("editAgentType", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>